import { LANGUAGE_SELECT } from './actions';

const languageKey = 'language';
const availableLanguages = ['zh-CN', 'en-GB'];

const defaultLanguage =
  availableLanguages.indexOf(navigator.language) > -1 ? navigator.language : 'en-GB';
const isValid = availableLanguages.indexOf(localStorage.getItem(languageKey)) > -1;
const initialState = isValid ? localStorage.getItem(languageKey) : defaultLanguage;

export function languageSelect(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_SELECT:
      localStorage.setItem(languageKey, action.language);
      return action.language;
    default:
      return state;
  }
}
