import React from 'react';

import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import _omit from 'lodash/omit';
import _concat from 'lodash/concat';

const DisplayFormikState = (props) => (
  <ReactJson
    style={{ margin: '1rem 0' }}
    name="props"
    src={_omit(props, _concat(['intl'], [...props.omissions, 'omissions'] || []))}
  />
);

DisplayFormikState.propTypes = {
  omissions: PropTypes.arrayOf(PropTypes.string),
};

DisplayFormikState.defaultProps = {
  omissions: [],
};

/**
 * Based on solution from this thread -> https://github.com/jaredpalmer/formik/issues/1484#issuecomment-490262152
 *
 * This component provides FormikEffects to be used.
 *
 * @param {Formik} formik - formik render prop (reference -> https://jaredpalmer.com/formik/docs/api/formik#formik-render-methods-and-props)
 * @param {function} onSubmissionError - callback when form submits and is not valid
 */
function FormikEffects({ formik, onSubmissionError }) {
  const effect = () => {
    if (formik.submitCount > 0 && !formik.isValid) {
      onSubmissionError(formik);
    }
  };
  React.useEffect(effect, [formik.submitCount]);
  return null;
}

FormikEffects.propTypes = {
  formik: PropTypes.shape({
    submitCount: PropTypes.number,
    isValid: PropTypes.bool,
  }).isRequired,
  onSubmissionError: PropTypes.func,
};

export { FormikEffects, DisplayFormikState };
