import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { hideEmail } from 'Core/helpers';
import { getGeneralParameters } from 'Auth/actions';
import { verifyCheck } from './actions';
import { HEARTBEAT_TIMEOUT } from './constants';
import { getNumDaysForReverify } from './selectors';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.oneOf(['new-device', 'unverified']).isRequired,
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }),

  getGeneralParameters: PropTypes.func,
  verifyCheck: PropTypes.func,
  numDaysForReverify: PropTypes.number,
};

const componentName = 'verification';

const mapDispatchToProps = (dispatch) => ({
  getGeneralParameters: () => dispatch(getGeneralParameters()),
  verifyCheck: (token) => dispatch(verifyCheck(token)),
});

const mapStateToProps = (state) => ({
  numDaysForReverify: getNumDaysForReverify(state),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const statusToIntlMap = {
  'new-device': 'device',
  unverified: 'identity',
};

const VerificationEmail = ({
  match,
  location,
  getGeneralParameters,
  numDaysForReverify,
  verifyCheck,
}) => {
  const type = _get(match, 'params.type');
  const token = _get(match, 'params.token');
  const email = _get(location, 'state.email');
  const intlType = statusToIntlMap[type];
  const hiddenEmail = hideEmail(email || '{email}');

  useEffect(() => {
    if (type === 'unverified') {
      getGeneralParameters();
    }

    setTimeout(() => verifyCheck(token), HEARTBEAT_TIMEOUT);
  }, []);

  return (
    <div className={componentName}>
      <h3 className="mb-10">
        <FormattedMessage id={`login.verification.${intlType}.title`} />
      </h3>
      <p className="size-14 mb-20">
        <FormattedMessage
          id={`login.verification.${intlType}.notice`}
          values={{
            amount: numDaysForReverify,
          }}
        />
      </p>
      <p className="size-14 mb-20">
        <FormattedMessage
          id={`login.verification.${intlType}.email_sent`}
          values={{
            email: <span className={'strong'}>{hiddenEmail}</span>,
          }}
        />
      </p>
      <p className="size-14 mb-20">
        <FormattedMessage id={'login.verification.see_email'} />
      </p>
    </div>
  );
};

VerificationEmail.propTypes = propTypes;

export default enhance(VerificationEmail);
