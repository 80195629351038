import { asyncAction, clearError } from '@showtime/utility';
import { showBanner } from 'NotificationBanner';
import { getMe } from 'Auth/actions';

const key = 'settings.updateProfile';

export function updateProfile(values) {
  return asyncAction({
    key,
    url: '/api/user/me',
    method: 'PUT',
    body: values,
    success(response) {
      // TODO: Is there any other success than 202??
      if (response.responseStatus === 202) {
        this.dispatch(showBanner('success', 'profile.updated'));
        this.dispatch(getMe());
      }
    },
    error(err) {
      switch (err.status) {
        case 400:
        case 500: {
          this.dispatch(showBanner('error', 'login.unexpected_error'));
          this.dispatch(clearError(key));
          break;
        }
        default:
          break;
      }
    },
  });
}
