const en_GB = {
  yes: 'Yes',
  no: 'No',
  all: 'All',
  back: 'Back',
  clear: 'Clear',
  edit: 'Edit',
  apply: 'Apply',
  reset: 'Reset',
  select: 'Select',
  selected: 'selected',
  deselect: 'deselect',
  deselected: 'deselected',
  select_all: 'Select all',
  cancel: 'Cancel',
  save: 'Save',
  saving: 'Saving',
  close: 'Close',
  submit: 'Submit',
  delete: 'Delete',
  noData: 'No data',
  unknown: 'Unknown',
  undo: 'Undo',
  new: 'New',
  active: 'active',
  inactive: 'inactive',
  visible: 'visible',
  required: 'required',
  replace: 'replace',
  updateItems: 'Update {value} items',
  success: 'Success!',

  // for form fields
  isEmptyField: "Can't be blank",

  classified: 'Classified',
  unClassified: 'Unclassified',
  deselectAll: 'Deselect All',
  undoSelected: 'Undo selected',
  areYouSure: 'Are you sure ?',
  formTitle: 'Form Title',

  // User Settings
  user_settings: 'User Settings',
  profile_settings: 'Profile Settings',
  'profile.password': 'Password',
  'profile.first_name': 'First Name',
  'profile.validation.first_name_length': 'First name cannot be longer than {length} characters',
  'profile.last_name': 'Last Name',
  'profile.validation.last_name_length': 'Last name cannot be longer than {length} characters',
  'profile.update_profile': 'Update Profile',
  'profile.language': 'Language',
  'profile.currency': 'Currency',
  'profile.updated': 'Profile updated successfully',
  'password.updated': 'Password updated successfully',
  'profile.required_field': 'Required Field',
  'profile.please_complete_fields': 'Please complete the required fields',

  // Password Changes
  'passwords.current_password': 'Current Password',
  'passwords.new_password': 'New Password',
  'passwords.confirm_password': 'Confirm New Password',
  'passwords.change_password': 'Change Password',
  'passwords.reset_password': 'Reset Password',
  'reset_passwords.change_password': 'Reset Your Password',
  'passwords.please_change_password': 'Please change your password',
  'passwords.expired':
    'Your password has expired and must be changed. Please enter your new password below',
  'passwords.requirements':
    'Passwords must contain: At least 8 characters, Minimum of 1 capital letter, Minimum of 1 number',
  'passwords.invalid': 'Invalid password, please note password requirements',
  'passwords.must_match': 'The password and confirmation do not match',
  'passwords.invalid_token': 'The reset password token has either expired or has already been used, please follow the forgot password link on the login page to generate a new token',
  'passwords.incorrect': 'The password you entered is incorrect. Please try again.',
  'passwords.validations.does_not_match_last_amount':
    'The password you entered is the same as one of your previous {amount} passwords. Please try a new password.',

  // Password validation
  'passwords.must_contain': 'Your password must contain:',
  'passwords.validations.length': 'At least {amount} or more characters',
  'passwords.validations.letters':
    'At least {amount} {amount, plural, one {letter} other {letters}}',
  'passwords.validations.lowercase_letters':
    'At least {amount} lowercase {amount, plural, one {letter} other {letters}}',
  'passwords.validations.uppercase_letters':
    'At least {amount} capital {amount, plural, one {letter} other {letters}}',
  'passwords.validations.numbers':
    'At least {amount} {amount, plural, one {number} other {numbers}}',
  'passwords.validations.special_characters':
    'At least {amount} {amount, plural, one {symbol} other {symbols}}',
  'passwords.validations.previous_num_passwords':
    'Must be different from previous {amount, plural, one {password} other {# passwords}}',

  'passwords.error.failed_validations':
    'Your password failed the following {amount, plural, one {validation} other {validations}}:',

  // Login
  'login.welcome_message': 'Log in to access your account',
  'login.sign_in': 'Sign in',
  'login.password': 'Password',
  'login.select_language': 'Select language',
  'login.invalid_email': 'Invalid Username',
  'login.invalid_email_format': 'Invalid Email',
  'login.short_password': 'Minimun password length is 6 characters',
  'login.invalid_credentials':
    'The username or password you have entered is incorrect. Please try again.',
  'login.account_deactivated':
    'Your Account has been deactivated. Please contact your Account Administrator if you believe this is an error.',
  'login.email_password_error': 'Please enter your email and password',
  'login.forgot_pass': 'Forgot Your Password?',
  'login.forgot_pass.email_not_found': 'Email Not Found',
  'login.forgot_pass.unexpected_error': 'Unexpected Server Error',
  'login.forgot_pass_desc':
    "Enter your email address below and we'll send you password reset instructions.",
  'login.forgot_pass.success_message_title': 'Success!',
  'login.forgot_pass.success_message':
    'We’ve sent an email to: {email} with password reset instructions',
  'login.forgot_pass.success_note_1':
    'Note: If you don’t receive an email soon, please check your “Spam” folder as sometimes it gets filtered as spam!',
  'login.forgot_pass.success_note_2':
    'If it’s not in your spam, please contact your Champion User, who can assist with resetting your password.',

  'login.forgot_pass.account_deactivated_title': 'Account Deactivated',
  'login.forgot_pass.account_deactivated_message':
    'We could not send you a reset password email as your account has been deactivated. Please contact your Account Administrator if you believe this is an error.',
  'login.send_email': 'Send Email',
  'login.email': 'Email',
  'login.email_not_found': 'E-mail address not registered',
  'login.email_instructions': 'Email with instructions was sent',
  'login.email_required': 'E-mail is required',
  'login.password_confirm': 'CONFIRM PASSWORD',
  'login.reset_password': 'Reset Password',
  'login.remember_me': 'Stay Signed In',
  'login.password_not_blank': 'Cannot leave blank',
  'login.password_dont_match': 'Passwords do not match',
  'login.username_placeholder': 'Please enter email or username',
  'login.password_placeholder': 'Please enter password',
  'login.reset_password.title': 'Create New Password',
  'login.reset_password.new_password': 'New Password',
  'login.reset_password.confirm_new_password': 'Confirm Password',
  'login.reset_password.tooltip.head': 'Your password must contain:',
  'login.reset_password.tooltip.option1': 'At least 8 characters',
  'login.reset_password.tooltip.option2': '1 Capital',
  'login.reset_password.tooltip.option3': '1 Number',
  'login.invalid_username_password': 'The email or password you entered is incorrect.',
  'login.unexpected_error': 'An unexpected error occurred',
  'login.updated_password':
    'Your password has been updated. You can now login with your new password.',
  'login.verification.device.title': 'Verify your account',
  'login.verification.device.notice':
    "We noticed a login from a new device and want to make sure it's you.",
  'login.verification.device.email_sent':
    "For your account security, we've sent an email to {email} to further verify your identity.",
  'login.verification.identity.title': 'Verify your identity',
  'login.verification.identity.notice':
    'For your account security, you need to re-verify your identity every {amount, plural, one {day} other {# days}}.',
  'login.verification.identity.email_sent':
    "We've sent an email to {email} to further verify your identity.",
  'login.verification.see_email':
    'Please follow the instructions on the email to complete the verification.',
  'login.verification.success.title': 'Thank you!',
  'login.verification.success.notice': 'Thanks for verifying your identity.',
  'login.verification.success.can_access':
    'You may now access the Showtime Analytics platform on the requested device.',
  'login.error.expired30Minutes':
    'Your session has expired due to inactivity for 30 mins. Please log in again.',
  'login.error.expiredVerificationToken':
    'This verification link has expired. Please enter your login details again to resend the verification email.',
  'login.verification.error':
    'Something went wrong with your verification request, please try to login again',

  // Select Language
  'select_language.language': 'Language',
  'select_language.change_text': 'Change',
  back_to_signin: 'Back to Sign in',
  'select_language.english': 'English',
  'select_language.chinese': 'Chinese',

  // hint texts
  search_bar_hint_text: 'Search',

  // Search
  'search.loading': 'Loading...',
  'search.no_found': 'No Results Found',

  // Locales
  'locale.en': 'English',
  'locale.es': 'Spanish',
  'locale.de': 'German',
  'locale.zh': 'Chinese',
  'locale.pt': 'Portuguese',
  'locale.fr': 'French',
  'locale.no': 'Norwegian',
  'locale.ru': 'Russian',
  'locale.ko': 'Korean',
  // Custom languages as dialects for Arena.
  'locale.es-ar': 'Spanish',
  'locale.en-ar': 'English',
};

export default en_GB;
