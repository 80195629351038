const es_MX = {
  "yes": "Sí",
  "no": "No",
  "all": "Todo",
  "back": "Atrás",
  "clear": "Borrar",
  "edit": "Editar",
  "apply": "Aplicar",
  "reset": "Restablecer",
  "select": "Seleccionar",
  "selected": "seleccionado",
  "deselect": "deseleccionar",
  "deselected": "deseleccionado",
  "select_all": "Seleccionar todo",
  "cancel": "Cancelar",
  "save": "Guardar",
  "saving": "Guardando",
  "close": "Cerrar",
  "submit": "Enviar",
  "delete": "Borrar",
  "noData": "No existen datos",
  "unknown": "Desconocido",
  "undo": "Deshacer",
  "new": "Nuevo",
  "active": "activo",
  "inactive": "inactivo",
  "visible": "visible",
  "required": "requerido",
  "replace": "reemplazar",
  "updateItems": "Actualizar {value} ítems",
  "success": "¡Éxito!",
  "isEmptyField": "No puede estar en blanco",
  "classified": "Clasificado",
  "unClassified": "Sin clasificar",
  "deselectAll": "Deseleccionar Todo",
  "undoSelected": "Deshacer selección",
  "areYouSure": "¿Está seguro?",
  "formTitle": "Título de la Plantilla",
  "user_settings": "Configuración de Usuario",
  "profile_settings": "Configuración de Perfil",
  "profile.password": "Contraseña",
  "profile.first_name": "Nombre",
  "profile.validation.first_name_length": "El nombre no puede tener más de {length} caracteres.",
  "profile.validation.last_name_length": "El apellido no puede tener más de {length} caracteres.",
  "profile.last_name": "Apellido",
  "profile.update_profile": "Actualizar Perfil",
  "profile.language": "Idioma",
  "profile.currency": "Moneda",
  "profile.updated": "Perfil actualizado con éxito",
  "profile.required_field": "Campo Requerido",
  "password.updated": "Contraseña actualizada con éxito",
  "passwords.current_password": "Contraseña Actual",
  "passwords.new_password": "Nueva Contraseña",
  "passwords.confirm_password": "Confirmar Nueva Contraseña",
  "passwords.change_password": "Cambiar Contraseña",
  "passwords.requirements": "Las contraseñas deben contener: Al menos 8 caracteres, Mínimo 1 letra mayúscula, Mínimo de 1 número",
  "passwords.invalid": "Contraseña no válida, por favor tenga en cuenta los requisitos para la contraseña",
  "passwords.must_match": "La contraseña y la confirmación no coinciden",
  "passwords.invalid_token": "El token que se ingresó no es válido, intente restablecerlo nuevamente",
  "login.sign_in": "Iniciar Sesión",
  "login.password": "Contraseña",
  "login.select_language": "Seleccionar idioma",
  "login.invalid_email": "Nombre de Usuario Invalido",
  "login.invalid_email_format": "Correo Electrónico Inválido",
  "login.short_password": "La longitud mínima de la contraseña es de 6 caracteres",
  "login.invalid_credentials": "Combinación de nombre de usuario o contraseña inválida",
  "login.forgot_pass": "¿Olvidaste tu contraseña?",
  "login.forgot_pass.email_not_found": "Correo electrónico no encontrado",
  "login.forgot_pass.unexpected_error": "Error inesperado del servidor",
  "login.forgot_pass.success_message_title": "¡Éxito!",
  "login.forgot_pass.success_message": "Hemos enviado un correo electrónico a: {email} con instrucciones para restablecer la contraseña.",
  "login.forgot_pass.success_note_1": "Nota: ¡Si no recibe un correo electrónico pronto, revise su carpeta de \"Spam\" ya que a veces se filtra como correo spam!",
  "login.forgot_pass.success_note_2": "Si no está en su correo no deseado, comuníquese con su Usuario Champion, quien puede ayudarlo a restablecer su contraseña.",
  "login.forgot_pass_desc": "Ingrese su dirección de correo electrónico a continuación y le enviaremos las instrucciones para restablecer su contraseña.",
  "login.send_email": "Enviar correo electrónico",
  "login.email": "Correo electrónico",
  "login.email_not_found": "Dirección de correo electrónico no registrada",
  "login.email_instructions": "Correo electrónico con instrucciones fue enviado",
  "login.email_required": "Correo electrónico es requerido",
  "login.password_confirm": "CONFIRMAR CONTRASEÑA",
  "login.reset_password": "Restablecer Contraseña",
  "login.reset_password.title": "Crear Nueva Contraseña",
  "login.reset_password.new_password": "Nueva Contraseña",
  "login.reset_password.confirm_new_password": "Confirmar Nueva Contraseña",
  "login.reset_password.tooltip.head": "Su contraseña debe contener:",
  "login.reset_password.tooltip.option1": "Al menos 8 carácteres",
  "login.reset_password.tooltip.option2": "1 Letra Mayúscula",
  "login.reset_password.tooltip.option3": "1 Número",
  "login.remember_me": "Mantener conexión",
  "login.password_not_blank": "No se puede dejar en blanco",
  "login.password_dont_match": "Las contraseñas no coinciden",
  "login.username_placeholder": "Por favor ingrese su correo electrónico o nombre de usuario",
  "login.password_placeholder": "Por favor ingrese contraseña",
  "login.invalid_username_password": "El correo electrónico o la contraseña son incorrectos.",
  "login.unexpected_error": "Ha ocurrido un error inesperado",
  "login.updated_password": "Su contraseña ha sido actualizada. Ahora puede iniciar sesión con su nueva contraseña.",
  "login.welcome_message": "Inicie sesión para acceder a su cuenta",
  "select_language.language": "idioma",
  "select_language.change_text": "Cambiar",
  "select_language.english": "Inglés",
  "select_language.chinese": "Chino",
  "back_to_signin": "Volver a Iniciar Sesión",
  "search_bar_hint_text": "Buscar",
  "search.loading": "Cargando...",
  "search.no_found": "No se han encontrado resultados",
  "locale.en": "Inglés",
  "locale.es": "Español",
  "locale.de": "Alemán",
  "locale.zh": "Chino",
  "locale.pt": "Portugués",
  "locale.fr": "Francés",
  "locale.no": "Noruego",
  "locale.ru": "Ruso"
};

export default es_MX;
