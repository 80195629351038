import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import _get from 'lodash/get';
import * as Yup from 'yup';
import { isLoading } from '@showtime/utility';
import { Alert } from '@showtime/sprocket';

import { profilePropType, profileLangCurrencyPropType } from 'Core/propTypes';
import { getAvailableCurrencies, getAvailableLanguages } from './selectors';
import { updateProfile } from './actions';
import ProfileForm from './ProfileForm';

const propTypes = {
  intl: intlShape.isRequired,
  profile: profilePropType.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  updateProfile: PropTypes.func.isRequired,
  availableCurrencies: PropTypes.arrayOf(profileLangCurrencyPropType).isRequired,
  availableLanguages: PropTypes.arrayOf(profileLangCurrencyPropType).isRequired,
};

const MAX_FIRSTNAME_LENGTH = 50;
const MAX_LASTNAME_LENGTH = 80;

const mapStateToProps = (state, ownProps) => ({
  profile: state.profile,
  isUpdating: isLoading(state, 'settings.updateProfile'),
  availableLanguages: getAvailableLanguages(state, ownProps),
  availableCurrencies: getAvailableCurrencies(state, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (query) => dispatch(updateProfile(query)),
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export class Settings extends Component {
  static propTypes = propTypes;

  formikRef = React.createRef();

  ProfileValidationSchema = Yup.object().shape({
    name: Yup.object().shape({
      firstName: Yup.string()
        .required(this.props.intl.formatMessage({ id: 'profile.please_complete_fields' }))
        .max(
          MAX_FIRSTNAME_LENGTH,
          this.props.intl.formatMessage(
            { id: 'profile.validation.first_name_length' },
            { length: MAX_FIRSTNAME_LENGTH }
          )
        ),
      lastName: Yup.string()
        .required(this.props.intl.formatMessage({ id: 'profile.please_complete_fields' }))
        .max(
          MAX_LASTNAME_LENGTH,
          this.props.intl.formatMessage(
            { id: 'profile.validation.last_name_length' },
            { length: MAX_LASTNAME_LENGTH }
          )
        ),
    }),
  });

  renderErrorMessages = () => {
    const errors = _get(this, 'formikRef.current.state.errors', {});
    let message = false;
    if (errors.name && errors.name.firstName) {
      message = errors.name.firstName;
    } else if (errors.name && errors.name.lastName) {
      message = errors.name.lastName;
    }

    return message ? <Alert status="error">{message}</Alert> : null;
  };

  render() {
    const {
      isUpdating,
      profile: { id: profileId, name, email, languageId, currencyId },
      availableLanguages,
      availableCurrencies,
      updateProfile,
    } = this.props;
    const renderErrorMessages = this.renderErrorMessages;

    return (
      <div className="settings-form">
        {profileId && (
          <Formik
            initialValues={{ name, email, languageId, currencyId }}
            onSubmit={updateProfile}
            validationSchema={this.ProfileValidationSchema}
            validateOnChange={false}
            isInitialValid={true}
            ref={this.formikRef}
            render={(formProps) => (
              <Fragment>
                {renderErrorMessages()}

                <h1 className="settings-form__title">
                  <FormattedMessage id={'profile_settings'} />
                </h1>

                <ProfileForm
                  availableLanguages={availableLanguages}
                  availableCurrencies={availableCurrencies}
                  isUpdating={isUpdating}
                  {...formProps}
                />
              </Fragment>
            )}
          />
        )}
      </div>
    );
  }
}

export default enhance(Settings);
