const zh_CN = {
  // common keys
  all: '所有',
  apply: '应用',
  back: '返回',
  cancel: '取消',
  clear: '清除选择',
  close: '关闭',
  no: '否',
  please_confirm: '请确认',
  reset: '重置',
  select: '选定',
  select_all: '全选',
  select_none: '取消选择',
  size: '人数',
  submit: '发送',
  yes: '是',
  edit: '修改',
  male: '男',
  female: '女',
  text_with_copy: '{text}_副本',
  save: '保存',
  saving: 'TODO',
  delete: '删除',
  no_results: '无记录',
  selected: 'TODO',
  deselect: 'TODO',
  deselected: 'TODO',
  noData: 'TODO',
  unknown: '未知',
  undo: 'TODO',
  new: 'TODO',
  active: 'TODO',
  inactive: 'TODO',
  updateItems: 'TODO {value} TODO',

  // for form fields
  isEmptyField: 'TODO',

  classified: 'TODO',
  unClassified: 'TODO',
  deselectAll: 'TODO',
  undoSelected: 'TODO',
  areYouSure: 'TODO',
  formTitle: 'TODO',

  // Login
  'login.sign_in': '登录',
  'login.username': '用户名',
  'login.password': '密码',
  'login.select_language': '选择语言',
  'login.invalid_email': '用户名无效',
  'login.invalid_email_format': '电子邮件地址无效',
  'login.short_password': '密码太短，至少要6位数',
  'login.invalid_credentials': '用户名或密码不正确',
  'login.forgot_pass': '忘记密码',
  'login.forgot_pass_desc': '请输入你的邮箱地址，我们将发送重置密码的步骤',
  'login.send_email': '发送邮件',
  'login.email': '电子邮件',
  'login.email_not_found': '电子邮件帐户不存在',
  'login.email_instructions': '重置密码的步骤已发送',
  'login.email_required': '一个有效的电子邮件地址是必需的',
  'login.password_confirm': '确认你的密码',
  'login.password_reset_submit': '发送',
  'login.reset_password': '重置密码',
  'login.remember_me': '保持登录',
  'login.password_not_blank': '密码不能为空',
  'login.password_dont_match': '密码不匹配',
  'login.username_placeholder': '请输入邮箱地址或用户名',
  'login.password_placeholder': '请输入密码',
  'login.reset_password.title': 'TODO',
  'login.reset_password.new_password': 'TODO',
  'login.reset_password.confirm_new_password': 'TODO',
  'login.reset_password.tooltip.head': 'TODO:',
  'login.reset_password.tooltip.option1': 'TODO',
  'login.reset_password.tooltip.option2': 'TODO',
  'login.reset_password.tooltip.option3': 'TODO',

  // Select Language
  'select_language.language': '语言',
  'select_language.change_text': '更换',
  'back_to_signin': '回到登录界面',
  'select_language.english': '英文',
  'select_language.chinese': '简体中文',

  // hint texts
  search_bar_hint_text: 'TODO',

  // Search
  'search.loading': 'TODO',
  'search.no_found': 'TODO',

  //  navigation items items
  navigationCircuitSettings: 'TODO',
  navigationDataManager: 'TODO',
  navigationSPH: 'TODO',
  navigationContactSocial: 'TODO',
  navigationCampaignTagging: 'TODO',
  navigationSuppressionList: 'TODO',

  //  Data manager page
  dataManagerTitle: 'TODO',
  categoryAdminTitle: 'TODO',
  datManagerTabsStock: 'TODO',
  datManagerTabsTickets: 'TODO',
  datManagerTabsMovies: 'TODO',
  datManagerTabsLocations: 'TODO',
  datManagerTabsDevices: 'TODO',
  datManagerLoyaltyMembership: 'TODO',
  datManagerEvents: 'TODO',

  //  Data manager page ->>>>  Movies tab
  dataManagerEventsSearch: 'TODO',
  datManagerMovieTitles: 'TODO',
  dataManagerNoSuggestions: 'TODO',
  dataManagerSuggested: 'TODO',

  //  Data manager page ->>>>  Stock tab
  datManagerCategoryTitle: 'TODO',
  datManagerSubCategoryTitle: 'TODO',

  //  Data manager page ->>>>  Ticket tab
  datManagerTicketsCategoryTitle: 'TODO',
  datManagerTicketsTitle: 'TODO',

  //  Data manager page ->>>>  Location tab
  datManagerLocationTitle: 'TODO',
  datManagerLocationStateTitle: 'TODO',
  datManagerLocationCityTitle: 'TODO',
  datManagerLocationAreaTitle: 'TODO',

  //  Data manager page ->>>>  Devices tab
  datManagerDevicesTypesTitle: 'TODO',
  datManagerDevicesThirdPartyTitle: 'TODO',

  // Spends page
  SpendsTitle: 'TODO',
  SpendsLeftPanelTitle: 'TODO',
  SpendsRightPanelTitle: 'TODO',

  //  Data manager page ->>>>  Stock tab
  datManagerLoyaltyMembershipTitle: 'TODO',
  dataManagerLevel1: 'TODO',
  dataManagerLevel2: 'TODO',

  //  Data manager page ->>>>  Other section
  otherSectionUserCreate: 'TODO',
  otherSectionAccountInfo: 'TODO',
  otherSectionContacts: 'TODO',
  otherSectionTagging: 'TODO',

  // Preference center
  preferenceCenterTitle: 'TODO',
  preferenceCenterManagerTab: 'TODO',
  preferenceCenterManagerSubTitle: 'TODO',
  preferenceCenterTableColName: 'TODO',
  preferenceCenterTableColStatus: 'TODO',
  preferenceCenterSetupTab: 'TODO',
  preferenceCenterSetupTabTab: 'TODO',
  preferenceCenterSetupSubTitle: 'TODO',
  preferenceCenterSetupLogo: 'TODO',
  preferenceCenterSetupBtn: 'TODO',
  preferenceCenterSetupDimension: 'TODO',
  preferenceCenterSetupFields: 'TODO',
  preferenceCenterSetupFieldsDescription: 'TODO',
  preferenceCenterSetupFieldsEmail: 'TODO',
  preferenceCenterSetupFieldsFirstName: 'TODO',
  preferenceCenterSetupFieldsLastName: 'TODO',
  preferenceCenterSetupFieldsBirth: 'TODO',
  preferenceCenterSetupFieldsPreferences: 'TODO',
  preferenceCenterSetupFieldsPreview: 'TODO',
};

export default zh_CN;
