/*
 * Showtime's password requirements:
 * - Minimum eight characters (?=.{8,})
 * - at least one lower case character (?=.*[a-z])
 * - at least one upper case character (?=.*[A-Z])
 * - at least one upper case character (?=.*[0-9])
 * */
export const PASSWORD_VALIDATION_REGEX = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

export const PASSWORD_POLICY_STATE_KEY = 'passwordPolicy';

// Validation rules
export const MIN_LETTERS_RULE = 'minLetters';
export const MIN_LOWERCASE_LETTERS_RULE = 'minLowerCase';
export const MIN_UPPERCASE_LETTERS_RULE = 'minUpperCase';
export const MIN_NUMBERS_RULE = 'minNumbers';
export const MIN_PASSWORD_LENGTH_RULE = 'minSize';
export const MIN_SPECIAL_CHARACTERS_RULE = 'minSpecialChars';
export const NUM_LAST_USED_PASSWORDS_RULE = 'numLastUsedPasswords';

export const RULE_MESSAGE_IDS = {
  [MIN_NUMBERS_RULE]: 'numbers',
  [MIN_PASSWORD_LENGTH_RULE]: 'length',
  [MIN_LETTERS_RULE]: 'letters',
  [MIN_LOWERCASE_LETTERS_RULE]: 'lowercase_letters',
  [MIN_UPPERCASE_LETTERS_RULE]: 'uppercase_letters',
  [MIN_SPECIAL_CHARACTERS_RULE]: 'special_characters',
  [NUM_LAST_USED_PASSWORDS_RULE]: 'previous_num_passwords',
};
