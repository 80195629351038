import { createReducer } from 'redux-starter-kit';
import { SET_VERIFICATION_DETAILS } from './actionTypes';

const initialDetails = {
  canLogin: false,
  deviceInfo: {
    make: '',
    location: '',
  },
};

const verificationDetails = createReducer(initialDetails, {
  [SET_VERIFICATION_DETAILS]: (_, action) => action.details,
});

export default {
  verificationDetails,
};
