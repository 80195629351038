import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormField, Input, Button } from '@showtime/sprocket';
import { injectIntl, intlShape } from 'react-intl';
// import { DisplayFormikState } from 'Core/helpers';
import _isEqual from 'lodash/isEqual';

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,

    hasUpdated: PropTypes.func,
    isUpdating: PropTypes.bool,

    // Props injected by Formik
    values: PropTypes.shape({
      email: PropTypes.string,
    }),

    // errors and touched can be empty, and will be populated by Formik if required
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
    isValid: PropTypes.bool,
    validateForm: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    const {
      validateForm,
      isUpdating,
      hasUpdated,
      values: {
        email
      }
    } = this.props;

    // Validating the form after it is updated to get any errors from server
    if (!isUpdating && prevProps.isUpdating) {
      validateForm();
    }

    // If the email value hasChanged call the hasUpdated callback
    if (!_isEqual(email, prevProps.values.email)) {
      hasUpdated();
    }
  }

  render() {
    const {
      intl: { formatMessage },
      values,
      touched,
      errors,
      isUpdating,
      handleSubmit,
      isValid
    } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <div className="mb-30">
          <h3 className="mb-15">{formatMessage({ id: 'login.forgot_pass' })}</h3>
          <p className="size-14">{formatMessage({ id: 'login.forgot_pass_desc' })}</p>
        </div>

        <FormField
          key="email"
          label={ formatMessage({ id: 'login.email' }) }
          errorText={ errors.email }
          hasError={(touched.email && errors.email && errors.email.length > 0)}
        >
          <Field
            name="email"
            onChange={this.fieldHasChanged}
            render={(props) => (
              <Input
                placeholder={formatMessage({ id: 'login.email' })}
                name="email"
                type="email"
                id="email"
                value={values.email}
                {...props}
              />
            )}
          />
        </FormField>


        <Button
          disabled={isUpdating || !isValid}
          className="full-width margin-top-md"
          type="primary"
          size="md"
          isSubmit
        >
          <span>{formatMessage({id: 'login.send_email'})}</span>
        </Button>


        {/*<DisplayFormikState {...this.props} />*/}
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ForgotPasswordForm);
