import { communicationReducer, COMMUNICATION_STATE_KEY } from '@showtime/utility';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { languageSelect } from 'Language/reducer';
import RedirectToProductReducer from 'RedirectToProduct/reducer';
import authReducers from 'Auth/reducers';
import {
  STATE_KEY as NOTIFICATION_BANNER_KEY,
  reducer as NotificationReducer,
} from 'NotificationBanner';
import { STATE_KEY as REDIRECT_TO_PRODUCT_STATE_KEY } from 'RedirectToProduct/constants';
import { passwordReducers } from 'Password';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    languageSelect,
    ...authReducers,
    ...passwordReducers,
    [REDIRECT_TO_PRODUCT_STATE_KEY]: RedirectToProductReducer,
    [COMMUNICATION_STATE_KEY]: communicationReducer,
    [NOTIFICATION_BANNER_KEY]: NotificationReducer,
  });

export default reducer;
