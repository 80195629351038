import { push } from 'connected-react-router';
import { asyncAction, error } from '@showtime/utility';
import { SET_VERIFICATION_DETAILS } from './actionTypes';
import { getMe } from '../actions';
import { HEARTBEAT_TIMEOUT } from './constants';
import { LOGIN_SEARCH_ERRORS } from './../constants';
import { HOME_URL } from 'Core/constants';

export const setVerificationDetails = (details) => ({
  type: SET_VERIFICATION_DETAILS,
  details,
});

export function verifyUser(token) {
  return (dispatch, getState) => {
    return dispatch(
      asyncAction({
        key: 'verification.verify',
        url: '/api/user/verify',
        method: 'POST',
        body: { token },
        clearErrors: false,
        success(response) {
          // Loading the profile, then setting the verification details
          if (response.canLogin) {
            dispatch(getMe()).then(() => {
              dispatch(push('/'));
            });
          } else {
            dispatch(setVerificationDetails(response));
          }
        },
        error() {
          const errorState = error(getState(), 'verification.verify');

          if (errorState.error.status === 404) {
            dispatch(
              push({
                pathname: HOME_URL,
                search: `?error=${LOGIN_SEARCH_ERRORS.expiredVerificationToken}`,
              })
            );
          }
        },
      })
    );
  };
}

export function verifyCheck(token) {
  return (dispatch, getState) => {
    return dispatch(
      asyncAction({
        key: 'verification.verifyCheck',
        url: `/api/user/verify-check/${token}`,
        method: 'POST',
        clearErrors: false,
        success(response) {
          if (response.hasVerified) {
            dispatch(getMe()).then(() => {
              dispatch(push('/'));
            });
          } else {
            setTimeout(() => dispatch(verifyCheck(token)), HEARTBEAT_TIMEOUT);
          }
        },
        error() {
          dispatch(push('/user/logout'));
        },
      })
    );
  };
}
