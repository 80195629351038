import React from 'react';
import Logo from '../Logo/Logo';

const Landing = ({ children }) => (
  <div className="flex bg-white bg-mobile">
    <div className={'flex flex-middle half-width authFormWrapper'}>
      <div className="height-500 full-width logo-mobile">
        <Logo />
      </div>
      <div className="height-500 full-width form-wrap">{children}</div>
    </div>
    <div className={'flex hide-mobile flex-middle half-width bgContainer'}>
      <div className="height-500 full-width">
        <Logo />
      </div>
    </div>
  </div>
);

export default Landing;
