import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import { Spinner } from '@showtime/sprocket';
import { isLoadingByPattern, errorByPattern } from '@showtime/utility';
import { verifyUser } from './actions';
import { deviceSelector, locationSelector } from './selectors';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
  canLogin: PropTypes.bool,
  isVerifying: PropTypes.bool,
};

// TODO: replace with store.
const defaultProps = {
  device: '',
  geolocation: '',
  isVerifying: false,
};

const componentName = 'verification';

const mapStateToProps = (state) => ({
  device: deviceSelector(state),
  geolocation: locationSelector(state),
  isVerifying: isLoadingByPattern(state, 'verification.verify'),
  error: errorByPattern(state, 'verification.verify'),
});

const mapDispatchToProps = { verifyUser };

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const Verification = ({ match, isVerifying, device, geolocation, verifyUser, error }) => {
  const token = _get(match, 'params.token');
  const errorStatus = _get(error, 'error.status', null);

  useEffect(() => {
    if (token) {
      verifyUser(token);
    }
  }, []);

  // Revisit the conditions/props
  if (isVerifying) {
    return <Spinner />;
  } else if (!_isNull(errorStatus)) {
    // likely a 500 or general error
    return (
      <div className={componentName}>
        <h3 className="mb-10 mt-50">
          <FormattedMessage id="login.unexpected_error" />
        </h3>
        <p className="size-14 mb-20">
          <FormattedMessage id="login.verification.error" />
        </p>

        <div className="mb-50">
          <Link to="/user/login">
            <FormattedMessage id="back_to_signin" />
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className={componentName}>
        <h3 className="mb-10">
          <FormattedMessage id="login.verification.success.title" />
        </h3>
        <p className="size-14 mb-20">
          <FormattedMessage id="login.verification.success.notice" />
        </p>
        <p className="size-14 mb-20">
          <FormattedMessage id="login.verification.success.can_access" />
        </p>
        <p className="verification-block size-14 mb-10">
          <i className="size-24 icon-desktop" />
          <span className="verification-block__text">{device}</span>
        </p>
        <p className="verification-block size-14 mb-10">
          <i className="size-24 icon-globe" />
          <span className="verification-block__text">{geolocation}</span>
        </p>
      </div>
    );
  }
};

Verification.propTypes = propTypes;
Verification.defaultProps = defaultProps;

export default enhance(Verification);
