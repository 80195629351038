import { createReducer } from 'redux-starter-kit';

import { SET_PASSWORD_POLICY } from './actionTypes';
import {
  PASSWORD_POLICY_STATE_KEY,
} from './constants';

const initialPolicy = [];
const passwordPolicyReducer = createReducer(initialPolicy, {
  [SET_PASSWORD_POLICY]: (_, action) => action.policy,
});

export default {
  [PASSWORD_POLICY_STATE_KEY]: passwordPolicyReducer
};
