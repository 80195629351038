import * as Yup from 'yup';
import XRegExp from 'xregexp';
import _constant from 'lodash/constant';

import {
  MIN_NUMBERS_RULE,
  MIN_PASSWORD_LENGTH_RULE,
  MIN_LOWERCASE_LETTERS_RULE,
  MIN_UPPERCASE_LETTERS_RULE,
  MIN_SPECIAL_CHARACTERS_RULE,
  NUM_LAST_USED_PASSWORDS_RULE,
  MIN_LETTERS_RULE,
} from './constants';

const getTest = (testFn) => ({ key, value, message }) => ({
  name: key,
  message,
  test: (testValue) => testFn(testValue, value),
});

const numbersPattern = /\d/g;
const testNumbers = (password, minNumbers) =>
  XRegExp.match(password, numbersPattern).length >= minNumbers;

const testLength = (password, minLength) => password.length >= minLength;

const lettersPattern = XRegExp('\\p{Letter}', 'g');
const testLetters = (password, minLetters) =>
  XRegExp.match(password, lettersPattern).length >= minLetters;

const lowercaseLettersPattern = XRegExp('\\p{Lowercase_Letter}', 'g');
const testLowercaseLetters = (password, minLetters) =>
  XRegExp.match(password, lowercaseLettersPattern).length >= minLetters;

const uppercaseLettersPattern = XRegExp('\\p{Uppercase_Letter}', 'g');
const testUppercaseLetters = (password, minLetters) =>
  XRegExp.match(password, uppercaseLettersPattern).length >= minLetters;

const specialCharactersPattern = /[$&+,:;=\\\\?@#|/'<>.^*()%!\-[\]{}]/g;
const testSpecialCharacters = (password, minCharacters) =>
  XRegExp.match(password, specialCharactersPattern).length >= minCharacters;

// TODO: sync with backend?
const testNumLastUsedPassword = _constant(true);

const ruleTesters = {
  [MIN_NUMBERS_RULE]: getTest(testNumbers),
  [MIN_PASSWORD_LENGTH_RULE]: getTest(testLength),
  [MIN_LOWERCASE_LETTERS_RULE]: getTest(testLowercaseLetters),
  [MIN_LETTERS_RULE]: getTest(testLetters),
  [MIN_UPPERCASE_LETTERS_RULE]: getTest(testUppercaseLetters),
  [MIN_SPECIAL_CHARACTERS_RULE]: getTest(testSpecialCharacters),
  [NUM_LAST_USED_PASSWORDS_RULE]: getTest(testNumLastUsedPassword),
};

export const makeRequiredSchema = (intl) =>
  Yup.string().required(intl.formatMessage({ id: 'profile.required_field' }));

export const makePasswordPolicySchema = (rules) => {
  let schema = Yup.string();

  rules.forEach((rule) => {
    const testRule = ruleTesters[rule.key];

    if (testRule) {
      schema = schema.test(testRule(rule));
    }
  });

  return schema;
};
