export const LOGIN_STATUS = {
  success: 'success',
  expired: 'expiredPassword',
  newDevice: 'new-device',
  unverified: 'unverified',
  unauthorized: 'unauthorized',
  unexpectedError: 'unexpected_error',
  deactivated: 'deactivated',
};

export const FORGOT_PASSWORD_STATUS = {
  success: 'success',
  deactivated: 'deactivated',
  unexpectedError: 'unexpectedError',
};

export const LOGIN_SEARCH_ERRORS = {
  expired30Minutes: 'expired30Minutes',
  expiredVerificationToken: 'expiredVerificationToken',
};
