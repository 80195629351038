import { asyncAction } from '@showtime/utility';
import { showBanner } from 'NotificationBanner';
import { push } from 'connected-react-router';

import { SET_PASSWORD_POLICY } from './actionTypes';

export function updatePassword(oldPassword, newPassword) {
  return asyncAction({
    key: 'settings.updatePassword',
    url: '/api/user/reset-password',
    method: 'POST',
    body: {
      oldPassword,
      password: newPassword,
    },
    clearErrors: true,
    success(response) {
      if (response.responseStatus === 202 || response.responseStatus === 200) {
        this.dispatch(showBanner('success', 'password.updated'));
      }

      return response;
    },
  });
}

export function resetPassword(password, key) {
  return (dispatch) => {
    return dispatch(
      asyncAction({
        key: 'landing.resetPassword',
        url: `/api/user/forgot-password/${key}`,
        method: 'POST',
        body: { password },
        clearErrors: true,
        success() {
          dispatch(
            push({
              pathname: '/user/login',
              state: {
                withChangedPassword: true,
              },
            })
          );
        },
      })
    );
  };
}

export function fetchPasswordPolicy() {
  return asyncAction({
    key: 'password.fetchPolicy',
    url: '/api/user/passwordValidationRules',
    method: 'GET',
    success(data) {
      this.dispatch({
        type: SET_PASSWORD_POLICY,
        policy: data.validationChecklist,
      });
    },
  });
}
