import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _isUndefined from 'lodash/isUndefined';
import { error, isLoading, clearError } from '@showtime/utility';

import { forgotPassword, setForgotPasswordStatus } from '../actions';
import { FORGOT_PASSWORD_STATUS } from '../constants';
import ForgotPasswordEmailSuccess from './ForgotPasswordEmailSucess';
import ForgotPasswordForm from './ForgotPasswordForm';

const propTypes = {
  isUpdating: PropTypes.bool,
  intl: intlShape.isRequired,
  forgotPasswordStatus: PropTypes.oneOf(Object.entries(FORGOT_PASSWORD_STATUS)),
  forgotPasswordError: PropTypes.shape({}),
  forgotPassword: PropTypes.func.isRequired,
  clearForgotPasswordState: PropTypes.func.isRequired,
  setForgotPasswordStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  forgotPasswordStatus: state.forgotPasswordStatus,
  isUpdating: isLoading(state, 'landing.forgetPassword'),
  forgotPasswordError: error(state, 'landing.forgetPassword'),
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(forgotPassword(email)),
  clearForgotPasswordState: () => dispatch(clearError('landing.forgetPassword')),
  setForgotPasswordStatus: (status) => dispatch(setForgotPasswordStatus(status)),
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

class ForgotPassword extends React.Component {
  static propTypes = propTypes;

  state = { email: '' };

  PasswordEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(this.props.intl.formatMessage({ id: 'login.invalid_email_format' }))
      .test(
        'not-found',
        this.props.intl.formatMessage({ id: 'login.forgot_pass.email_not_found' }),
        () => {
          return this.testServerError(404);
        }
      )
      .test(
        'unexpected-error',
        this.props.intl.formatMessage({ id: 'login.unexpected_error' }),
        () => {
          return this.testServerError(500);
        }
      )
      .required(this.props.intl.formatMessage({ id: 'login.password_not_blank' })),
  });

  componentWillUnmount() {
    // reset the request email success status back to false
    this.props.setForgotPasswordStatus(false);
  }

  testServerError = (status) => {
    const forgotPasswordError = this.props.forgotPasswordError;

    if (_isUndefined(forgotPasswordError)) {
      return true;
    } else {
      return !(forgotPasswordError.error.status === status);
    }
  };

  clearStateErrors = () => {
    const { forgotPasswordError, clearForgotPasswordState } = this.props;

    if (!_isUndefined(forgotPasswordError)) {
      clearForgotPasswordState();
    }
  };

  resetPass = (values) => {
    const email = values.email;
    this.setState({ email });
    this.props.forgotPassword(email);
  };

  renderForgotPasswordContent = () => {
    const {
      intl: { formatMessage },
      forgotPasswordStatus,
      isUpdating,
    } = this.props;

    switch (forgotPasswordStatus) {
      case FORGOT_PASSWORD_STATUS.success: {
        return <ForgotPasswordEmailSuccess email={this.state.email} />;
      }
      case FORGOT_PASSWORD_STATUS.deactivated: {
        return (
          <div className="mb-30">
            <h3 className="mb-15">
              {formatMessage({ id: 'login.forgot_pass.account_deactivated_title' })}
            </h3>
            <p className="size-14">
              {formatMessage({ id: 'login.forgot_pass.account_deactivated_message' })}
            </p>
          </div>
        );
      }
      default: {
        return (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={this.PasswordEmailSchema}
            onSubmit={this.resetPass}
            render={(formProps) => (
              <ForgotPasswordForm
                hasUpdated={this.clearStateErrors}
                isUpdating={isUpdating}
                {...formProps}
              />
            )}
          />
        );
      }
    }
  };

  render() {
    const renderForgotPasswordContent = this.renderForgotPasswordContent;
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Fragment>
        <div className="mb-50">
          <Link to="/user/login">{formatMessage({ id: 'back_to_signin' })}</Link>
        </div>

        {renderForgotPasswordContent()}
      </Fragment>
    );
  }
}

export default enhance(ForgotPassword);
