import _get from 'lodash/get';

export const deviceSelector = (state) => _get(state, 'verificationDetails.deviceInfo.make', '');
export const locationSelector = (state) =>
  _get(state, 'verificationDetails.deviceInfo.location', '');

export const getNumDaysForReverify = (state) => {
  const hours = _get(state, 'generalParameters.expirePolicy.lifetimeHours', 0);

  return hours ? Math.ceil(hours / 24) : hours;
};
