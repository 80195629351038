import { createSelector } from 'redux-starter-kit';
import _identity from 'lodash/identity';

import { getIntlFromProps } from 'Core/selectors';

import { PASSWORD_POLICY_STATE_KEY, RULE_MESSAGE_IDS } from './constants';
import { makeRequiredSchema, makePasswordPolicySchema } from './schemas';

const getPasswordPolicyRulesBase = createSelector(
  [PASSWORD_POLICY_STATE_KEY],
  _identity
);

export const getPasswordPolicyRules = createSelector(
  [getPasswordPolicyRulesBase, getIntlFromProps],
  (rules, intl) =>
    rules.map(({ key, value }) => ({
      key,
      value,
      message: intl.formatMessage(
        { id: `passwords.validations.${RULE_MESSAGE_IDS[key]}` },
        { amount: value }
      ),
    }))
);

export const getPasswordPolicyRulesDict = createSelector(
  [getPasswordPolicyRules],
  (rules) => {
    const result = {};

    rules.forEach((rule) => {
      result[rule.key] = rule;
    });

    return result;
  }
);

export const getPasswordRequiredSchema = createSelector(
  [getIntlFromProps],
  makeRequiredSchema
);

export const getPasswordPolicySchema = createSelector(
  [getPasswordPolicyRules],
  makePasswordPolicySchema
);
