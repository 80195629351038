
// TODO extract this from USER to Utility
const LOCALES = [{
  native: 'English',
  key: 'locale.en',
  countryCode: 'ie',
  code: 'en'
},{
  native: 'Español',
  key: 'locale.es',
  countryCode: 'es',
  code: 'es'
},{
  native: 'Deutsch',
  key: 'locale.de',
  countryCode: 'de',
  code: 'de'
},{
  native: '中文',
  key: 'locale.zh',
  countryCode: 'cn',
  code: 'zh'
},{
  native: 'Português',
  key: 'locale.pt',
  countryCode: 'br',
  code: 'pt'
},{
  native: 'Français',
  key: 'locale.fr',
  countryCode: 'fr',
  code: 'fr'
},{
  native: 'Norsk',
  key: 'locale.no',
  countryCode: 'no',
  code: 'no'
},{
  native: 'Русский',
  key: 'locale.ru',
  countryCode: 'ru',
  code: 'ru'
},{
  native: '한국어',
  key: 'locale.ko',
  countryCode: 'ko',
  code: 'ko',
}, {
  // Custom language like Spanish customer dialect.
  native: 'Arena',
  key: 'locale.es-ar',
  countryCode: 'es',
  code: 'es-ar',
}, {
  // Custom language like English customer dialect.
  native: 'Arena',
  key: 'locale.en-ar',
  countryCode: 'ie',
  code: 'en-ar',
}];

export default LOCALES;
