import * as actions from './actionTypes';

/**
 * Shows the success banner
 * @param {number} status
 * @param {number} message
 */
const showBanner = (status, message) => ({
  type: actions.SHOW_BANNER,
  status,
  message
});

/**
 * Hides the banner
 */
const hideBanner = () => ({
  type: actions.HIDE_BANNER,
});


export {
  showBanner,
  hideBanner
};
