import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { Spinner } from '@showtime/sprocket';
import { connect } from 'react-redux';
import { logoutRequest } from './actions';
import _isNull from 'lodash/isNull';

import { HOME_URL } from 'Core/constants';
import { parseUrlParameters } from 'Core/helpers';

import { LOGIN_SEARCH_ERRORS } from './constants';

const mapDispatchToProps = (dispatch) => ({
  logout: (url) => dispatch(logoutRequest(url)),
});

const mapStateToProps = ({ profile }) => ({
  userId: profile.id,
});

class Logout extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.logout();
  }

  logout() {
    const originalUrl = this.props.location.search || null;
    this.props.logout(originalUrl);
  }

  getErrorParams = () => {
    const searchParams = parseUrlParameters(this.props.location.search);

    if (searchParams.error && LOGIN_SEARCH_ERRORS[searchParams.error]) {
      return `?error=${searchParams.error}`;
    }

    return '';
  };

  render() {
    const getErrorParams = this.getErrorParams;
    return _isNull(this.props.userId) ? (
      <Redirect
        to={{
          pathname: HOME_URL,
          search: getErrorParams(),
        }}
      />
    ) : (
      <Spinner />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
