import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import { isAuthenticated } from 'Core/helpers';

import AppFrame from 'AppFrame';

import { Login, ForgotPassword, Logout, Verification, VerificationEmail } from 'Auth';
import Landing from 'Landing';

import Settings from 'Settings';
import Password from 'Password';
import RedirectToProduct from 'RedirectToProduct';

const PUBLIC_ROUTES = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    component: Password,
  },
  {
    path: '/password-expired/:token',
    component: Password,
  },
  {
    path: '/verification-required/:type/:token',
    component: VerificationEmail,
  },
  {
    path: '/verification/:token',
    component: Verification,
  },
  {
    path: '/logout',
    component: Logout,
  },
];

const PRIVATE_ROUTES = [
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/password',
    component: Password,
  },
];

const HOME_URL = '/user/login';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <AppFrame>
          <Component {...props} />
        </AppFrame>
      ) : (
        <Redirect
          push
          to={{
            pathname: HOME_URL,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Landing>
        <Component {...props} />
      </Landing>
    )}
  />
);

const DefaultRoute = () => (
  <Route
    path="/"
    render={() =>
      isAuthenticated() ? (
        <PublicRoute path="/" component={RedirectToProduct} />
      ) : (
        <Redirect push to={HOME_URL} />
      )
    }
  />
);

const UserComponents = ({ match }) => {
  const url = match.url;
  return (
    <Fragment>
      {PUBLIC_ROUTES.map((route) => (
        <PublicRoute key={route.path} path={`${url}${route.path}`} component={route.component} />
      ))}
      {PRIVATE_ROUTES.map((route) => (
        <PrivateRoute key={route.path} path={`${url}${route.path}`} component={route.component} />
      ))}
    </Fragment>
  );
};

const routes = () => (
  <Route
    path="/"
    render={() => (
      <Switch>
        <Route path="/user" component={UserComponents} />
        <DefaultRoute />
      </Switch>
    )}
  />
);

export default routes;
