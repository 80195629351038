import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ForgotPasswordEmailSuccess = ({ email }) => {
  return (
    <div>
      <h3>
        <FormattedMessage id={'login.forgot_pass.success_message_title'}/>
      </h3>
      <p className="size-14 mb-20">
        <FormattedMessage
          id={'login.forgot_pass.success_message'}
          values={{
            email: (<span className={'strong'}>{email}</span>)
          }}
        />
      </p>
      <p className="size-12 mb-20">
        <FormattedMessage id={'login.forgot_pass.success_note_1'}/>
      </p>
      <p className="size-12">
        <FormattedMessage id={'login.forgot_pass.success_note_2'}/>
      </p>
    </div>
  );
};

ForgotPasswordEmailSuccess.propTypes = {
  email: PropTypes.string.isRequired,
};

export default ForgotPasswordEmailSuccess;
