import LOCALES from './locales';
import CURRENCIES from './currencies';

const HOME_URL = '/user/login';
const LOCALSTORAGE_USER_KEY = 'showtime:user';
const LOCALSTORAGE_LOCALE_KEY = 'showtime:locale';
const DEFAULT_LOCALE = 'en';

export {
  HOME_URL,
  LOCALSTORAGE_USER_KEY,
  LOCALES,
  CURRENCIES,
  LOCALSTORAGE_LOCALE_KEY,
  DEFAULT_LOCALE
};
