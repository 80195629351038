import { asyncAction, error } from '@showtime/utility';
import { push } from 'connected-react-router';
import isNil from 'lodash/isNil';

import { LOCALSTORAGE_USER_KEY } from 'Core/constants';
import { setLocaleToStorage, parseUrlParameters } from 'Core/helpers';
import { saveOriginalUrl } from '../RedirectToProduct/actions';
import {
  FETCH_PROFILE_SUCCESS,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
  RESET_USER_PROFILE,
  SET_LOGIN_STATUS,
  SET_GENERAL_PARAMETERS,
} from './actionTypes';
import { LOGIN_STATUS, FORGOT_PASSWORD_STATUS } from './constants';

export const fetchProfileSuccess = (profile) => ({
  type: FETCH_PROFILE_SUCCESS,
  profile,
});

export const setForgotPasswordStatus = (status) => ({
  type: FORGOT_PASSWORD_EMAIL_SUCCESS,
  status,
});

export const resetUserProfile = () => ({
  type: RESET_USER_PROFILE,
});

export const setLoginStatus = (status) => ({
  type: SET_LOGIN_STATUS,
  status,
});

export const resetLoginStatus = () => (dispatch) => dispatch(setLoginStatus(''));

export function loginRequest({ email, password, rememberMe }) {
  return (dispatch, getState) => {
    dispatch(resetLoginStatus());

    return dispatch(
      asyncAction({
        key: 'landing.loginRequest',
        url: '/api/user/login',
        body: {
          email,
          password,
          rememberMe,
        },
        method: 'POST',
        useDefaultErrorHandler: false,
      })
    )
      .then(() => dispatch(getMe()))
      .then(() =>
        dispatch(() => {
          setLoginStatus('success');
          dispatch(push('/'));
        })
      )
      .catch(() => {
        const errorState = error(getState(), 'landing.loginRequest');
        const {
          error: { status, body },
        } = errorState;

        if (status === 401 && body && body.status) {
          //Based on the status of the 401 we may need to do a few different actions
          dispatch(setLoginStatus(body.status));
          switch (body.status) {
            case LOGIN_STATUS.expired: {
              dispatch(push(`/user/password-expired/${body.token}`));
              break;
            }
            case LOGIN_STATUS.newDevice:
            case LOGIN_STATUS.unverified: {
              dispatch(
                push({
                  pathname: `/user/verification-required/${body.status}/${body.token}`,
                  state: { email },
                })
              );
              break;
            }
          }
        } else {
          dispatch(setLoginStatus(LOGIN_STATUS.unexpectedError));
        }
      });
  };
}

export function logoutRequest(originalUrl) {
  const key = 'landing.logoutRequest';
  const url = '/api/user/logout';

  return asyncAction({
    key,
    url,
    method: 'POST',
    useDefaultErrorHandler: false,
    success() {
      window.localStorage.removeItem(LOCALSTORAGE_USER_KEY);
      this.dispatch(resetUserProfile());
      if (!isNil(originalUrl)) {
        const parseUrlObject = parseUrlParameters(originalUrl);
        this.dispatch(saveOriginalUrl(parseUrlObject.originalUrl));
      }
    },
  });
}

export function forgotPassword(email) {
  return (dispatch, getState) => {
    dispatch(
      asyncAction({
        key: 'landing.forgetPassword',
        url: '/api/user/forgot-password',
        method: 'POST',
        body: { email },
        clearErrors: false,
        useDefaultErrorHandler: false,
      })
    )
      .then(() => {
        dispatch(setForgotPasswordStatus(FORGOT_PASSWORD_STATUS.success));
      })
      .catch(() => {
        const errorState = error(getState(), 'landing.forgetPassword');
        const {
          error: { status, body },
        } = errorState;

        if (status === 401 && body && body.status) {
          if (body.status === FORGOT_PASSWORD_STATUS.deactivated) {
            dispatch(setForgotPasswordStatus(FORGOT_PASSWORD_STATUS.deactivated));
          }
        }
      });
  };
}

export function getMe() {
  return asyncAction({
    key: 'landing.getMe',
    url: '/api/user/me',
    method: 'GET',
    success(profile) {
      localStorage.setItem(LOCALSTORAGE_USER_KEY, JSON.stringify(profile));
      setLocaleToStorage(profile.language);
      this.dispatch(fetchProfileSuccess(profile));
    },
  });
}

export function getGeneralParameters() {
  return asyncAction({
    key: 'landing.getGeneralParameters',
    url: '/api/user/general-parameters',
    method: 'GET',
    success(parameters) {
      this.dispatch({
        type: SET_GENERAL_PARAMETERS,
        parameters,
      });
    },
  });
}
