import React from 'react';

import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
// import { Route, Redirect } from 'react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
import zh_CN from './i18n/zh_CN'; // eslint-disable-line camelcase
import en_GB from './i18n/en_GB'; // eslint-disable-line camelcase
import es_MX from './i18n/es_MX'; // eslint-disable-line camelcase

import configureStore, { history } from './store';
import Routes from './routes';
const store = configureStore();
addLocaleData([...en, ...zh]);

const selectMessages = (locale) => {
  let enGB = en_GB;
  let injectMessages = {};

  switch (locale) {
    case 'zh':
      injectMessages = zh_CN; // eslint-disable-line camelcase
      break;
    case 'es':
      injectMessages = es_MX; // eslint-disable-line camelcase
      break;
    default:
  }

  return {
    ...enGB,
    ...injectMessages,
  };
};

const App = ({ messages }) => (
  <IntlProvider locale="en" messages={messages}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </IntlProvider>
);

App.propTypes = {
  messages: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  messages: selectMessages(state.profile.language),
});

const ConnectedApp = connect(mapStateToProps)(App);

const Root = () => (
  <Provider store={store}>
    <ConnectedApp />
  </Provider>
);

export default Root;
