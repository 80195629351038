import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Alert } from '@showtime/sprocket';
import classnames from 'classnames';

import { STATE_KEY, BANNER_APPEARANCE_TIME } from './constants';
import { hideBanner } from './actions';

class NotificationBanner extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    status: PropTypes.string,
    message: PropTypes.string,
    hideBanner: PropTypes.func,
  };

  activeTimeout = false;

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (!this.activeTimeout && isVisible && !prevProps.isVisible) {
      this.activeTimeout = setTimeout(this.shouldCloseBanner, BANNER_APPEARANCE_TIME);
    }
  }

  shouldCloseBanner = () => {
    const { isVisible, status, hideBanner } = this.props;

    if (this.activeTimeout && isVisible && status !== 'error') {
      this.activeTimeout = false;
      hideBanner();
    }
  };

  closeBannerWithButton = () => {
    clearTimeout(this.activeTimeout);
    this.activeTimeout = false;
    this.props.hideBanner();
  };

  render() {
    const { isVisible, status, message } = this.props;

    return (
      <Fragment>
        <div
          className={classnames('notification-banner', {
            'notification-banner--visible': isVisible,
          })}
        >
          <Alert
            type="banner"
            status={status}
            showCloseAction={true}
            onRequestClose={this.closeBannerWithButton}
          >
            {message !== '' && <FormattedMessage id={message} defaultMessage={message} />}
          </Alert>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isVisible: state[STATE_KEY].isVisible,
  status: state[STATE_KEY].status,
  message: state[STATE_KEY].message,
});

const mapDispatchToProps = (dispatch) => ({
  hideBanner: () => dispatch(hideBanner()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBanner);
