import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { FormField, Input, Button, Dropdown } from '@showtime/sprocket';
// import { DisplayFormikState } from 'Core/helpers';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

const DropdownWrapper = ({ options, field, form }) => (
  <Dropdown
    options={options}
    name={field.name}
    value={options ? options.find((option) => option.value === field.value) : ''}
    onChange={(option) => {
      form.setFieldValue(field.name, option);
    }}
    searchable={false}
    // joinValues is needed so everything doesn't break! ¯\_(ツ)_/¯
    // https://github.com/JedWatson/react-select/issues/2025#issuecomment-378306411
    joinValues
  />
);

export class ProfileForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    availableCurrencies: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    availableLanguages: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
      })
    ),

    // Boolean to tell component that the form is updating
    isUpdating: PropTypes.bool,

    // Props injected by Formik
    values: PropTypes.shape({
      name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      email: PropTypes.string,
      languageId: PropTypes.number,
      currencyId: PropTypes.number,
    }),
    // errors and touched can be empty, and will be populated by Formik if required
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
  };

  render() {
    const {
      intl: { formatMessage },
      values,
      isUpdating,
      handleSubmit,
      availableCurrencies,
      availableLanguages,
      errors,
      touched,
    } = this.props;

    const TEXT_INPUTS = [
      {
        key: 'name.firstName',
        label: formatMessage({ id: 'profile.first_name' }),
        isRequired: true,
        type: 'text',
      },
      {
        key: 'name.lastName',
        label: formatMessage({ id: 'profile.last_name' }),
        isRequired: true,
        type: 'text',
      },
      {
        key: 'email',
        label: formatMessage({ id: 'login.email' }),
        isRequired: false,
        type: 'text',
        isDisabled: true,
      },
    ];

    const DROPDOWN_INPUTS = [
      {
        key: 'languageId',
        label: formatMessage({ id: 'profile.language' }),
        options: availableLanguages,
      },
      {
        key: 'currencyId',
        label: formatMessage({ id: 'profile.currency' }),
        options: availableCurrencies,
      },
    ];

    return (
      <form onSubmit={handleSubmit} noValidate>
        {TEXT_INPUTS.map((textInput) => {
          const placeholder = textInput.label;
          const label = textInput.isRequired ? `${textInput.label} *` : textInput.label;
          const fieldError = _get(errors, textInput.key, '');
          const isTouched = _get(touched, textInput.key, false);

          return (
            <FormField
              key={textInput.key}
              label={label}
              hasError={isTouched && fieldError.length > 0}
            >
              <Field
                name={textInput.key}
                render={(props) => (
                  <Input
                    disabled={textInput.isDisabled}
                    placeholder={placeholder}
                    name={textInput.key}
                    type={textInput.type}
                    id={textInput.key}
                    value={_get(values, textInput.key)}
                    {...props}
                  />
                )}
              />
            </FormField>
          );
        })}

        {DROPDOWN_INPUTS.map((dropdownInput) => (
          <FormField key={dropdownInput.key} label={dropdownInput.label}>
            <Field
              id={dropdownInput.key}
              name={dropdownInput.key}
              value={values[dropdownInput.key]}
              render={(props) => (
                <DropdownWrapper options={_sortBy(dropdownInput.options, 'label')} {...props} />
              )}
            />
          </FormField>
        ))}

        <Button disabled={isUpdating} className="margin-top-md" type="primary" size="md" isSubmit>
          <span>{formatMessage({ id: 'profile.update_profile' })}</span>
        </Button>

        {/* <DisplayFormikState
          omissions={['availableLanguages', 'availableCurrencies', 'profile']}
          {...this.props}
        /> */}
      </form>
    );
  }
}
const mapStateToProps = ({ profile }) => ({
  profile,
});

export default connect(mapStateToProps)(injectIntl(ProfileForm));
