import PropTypes from 'prop-types';

export const profileNameShape = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export const profileNamePropType = PropTypes.shape(profileNameShape);

export const profileOrganizationShape = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export const profileOrganizationPropType = PropTypes.shape(profileOrganizationShape);

export const profileCircuitShape = {
  id: PropTypes.number,
  name: PropTypes.string,
  domain: PropTypes.string,
  startHour: PropTypes.number,
  startDay: PropTypes.number,
  weekend: PropTypes.string,
  attributes: PropTypes.string,
  currency: PropTypes.string,
};

export const profileCircuitPropType = PropTypes.shape(profileCircuitShape);

export const profileLangCurrencyShape = {
  id: PropTypes.number,
  isoCode: PropTypes.string,
};

export const profileLangCurrencyPropType = PropTypes.shape(profileLangCurrencyShape);

export const profileShape = {
  id: PropTypes.number,
  name: profileNamePropType,
  email: PropTypes.string,
  language: PropTypes.string,
  currency: PropTypes.string,
  languageId: PropTypes.number,
  currencyId: PropTypes.number,
  availableLanguages: PropTypes.arrayOf(profileLangCurrencyPropType),
  availableCurrencies: PropTypes.arrayOf(profileLangCurrencyPropType),
  organization: profileOrganizationPropType,
  circuits: PropTypes.arrayOf(profileCircuitPropType),
  policies: PropTypes.arrayOf(PropTypes.string),
  screenRules: PropTypes.arrayOf(PropTypes.string),
  configs: PropTypes.arrayOf(PropTypes.shape({})),
};

export const profilePropType = PropTypes.shape(profileShape);
