import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Button } from '@showtime/sprocket';
// import { DisplayFormikState } from 'Core/helpers';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';

import PasswordField from './PasswordField';
import PasswordPolicyTooltip from './PasswordPolicyTooltip';

export class PasswordForm extends Component {
  static defaultProps = {
    hasUpdated: _noop,
  };

  static propTypes = {
    intl: intlShape.isRequired,

    // Boolean to tell component that the form is updating
    isUpdating: PropTypes.bool,
    askForCurrentPassword: PropTypes.bool,
    hasUpdated: PropTypes.func,

    // Props injected by Formik
    values: PropTypes.shape({
      currentPassword: PropTypes.string,
      newPassword: PropTypes.string,
      confirmPassword: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
      firstValidated: PropTypes.bool,
    }),
    // errors and touched can be empty, and will be populated by Formik if required
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    validateForm: PropTypes.func,
    actionMessage: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    const { validateForm, isUpdating, values, hasUpdated } = this.props;

    // Validating the form after it is updated to get any errors from server
    if (!isUpdating && prevProps.isUpdating) {
      validateForm();
    }

    // If the value hasChanged call the hasUpdated callback
    Object.keys(values).forEach((key) => {
      if (!_isEqual(values[key], prevProps.values[key])) {
        hasUpdated();
      }
    });
  }

  renderNewPasswordTooltip = ({ fieldError }) => (
    <PasswordPolicyTooltip errors={fieldError} firstValidated={this.props.status.firstValidated} />
  );

  renderNewPasswordErrorMessage = (errors = []) => {
    const requiredError = errors.find(({ type }) => type === 'required');
    const invalidError = errors.find(({ type }) => type === 'invalid');

    if (requiredError) {
      return requiredError.message;
    } else if (invalidError) {
      return invalidError.message;
    } else if (errors.length > 0) {
      return this.props.intl.formatMessage({ id: 'passwords.invalid' });
    } else {
      return '';
    }
  };

  renderPasswordInputField = (key, labelKey, inputProps) => {
    const {
      intl: { formatMessage },
      values,
      errors,
      touched,
    } = this.props;

    const label = formatMessage({ id: labelKey });
    const fieldError = _get(errors, key, []);
    const isTouched = _get(touched, key, false);
    const value = _get(values, key);

    return (
      <PasswordField
        id={key}
        label={label}
        value={value}
        hasError={isTouched && fieldError.length > 0}
        fieldError={fieldError}
        {...inputProps}
      />
    );
  };

  render() {
    const renderPasswordInputField = this.renderPasswordInputField;
    const {
      intl: { formatMessage },
      isUpdating,
      handleSubmit,
      askForCurrentPassword,
      actionMessage,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        {askForCurrentPassword &&
          renderPasswordInputField('currentPassword', 'passwords.current_password')}

        {renderPasswordInputField('newPassword', 'passwords.new_password', {
          tooltipContentRender: this.renderNewPasswordTooltip,
          errorMessageRender: this.renderNewPasswordErrorMessage,
        })}

        {renderPasswordInputField('confirmPassword', 'passwords.confirm_password')}

        <Button
          disabled={isUpdating}
          type="primary"
          size={askForCurrentPassword ? 'md' : 'lg'}
          isSubmit
        >
          <span>{formatMessage({ id: actionMessage })}</span>
        </Button>

        {/* <DisplayFormikState omissions={['profile']} {...this.props} /> */}
      </form>
    );
  }
}
const mapStateToProps = ({ profile }) => ({
  profile,
});

export default connect(mapStateToProps)(injectIntl(PasswordForm));
