import React from 'react';
import isNil from 'lodash/isNil';
import { getProductPath } from 'Core/helpers';
import { Spinner } from '@showtime/sprocket';
import { connect } from 'react-redux';

class RedirectToProduct extends React.Component {
  componentDidMount() {
    // set domain used for login,
    // required to redirect support domain to correct showtime domain
    document.cookie = `st_domain=${window.location.host};domain=.showtimeanalytics.com;path=/;`;

    let path = getProductPath();
    if (!isNil(this.props.originalUrl)) {
      path = this.props.originalUrl;
    }
    window.location.href = path;
  }

  render() {
    return <Spinner />;
  }
}

const mapStateToProps = ({ navigation }) => ({
  originalUrl: navigation.originalUrl,
});

export default connect(mapStateToProps)(RedirectToProduct);
