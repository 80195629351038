import React, { useCallback, useEffect } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _identity from 'lodash/identity';
import { Spinner } from '@showtime/sprocket';

import {
  MIN_NUMBERS_RULE,
  MIN_PASSWORD_LENGTH_RULE,
  MIN_LOWERCASE_LETTERS_RULE,
  MIN_UPPERCASE_LETTERS_RULE,
  MIN_SPECIAL_CHARACTERS_RULE,
  NUM_LAST_USED_PASSWORDS_RULE,
  MIN_LETTERS_RULE,
} from './constants';
import { getPasswordPolicyRulesDict } from './selectors';
import { fetchPasswordPolicy } from './actions';
import { loading } from '@showtime/utility';

const propTypes = {
  intl: intlShape.isRequired,
  rules: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    })
  ).isRequired,
  firstValidated: PropTypes.bool,
  fetchPasswordPolicy: PropTypes.func,
  isLoading: PropTypes.bool,
};

// Ordering helper
const rulesOrder = [
  MIN_PASSWORD_LENGTH_RULE,
  MIN_LETTERS_RULE,
  MIN_LOWERCASE_LETTERS_RULE,
  MIN_UPPERCASE_LETTERS_RULE,
  MIN_NUMBERS_RULE,
  MIN_SPECIAL_CHARACTERS_RULE,
];

const mapStateToProps = (state, ownProps) => ({
  rules: getPasswordPolicyRulesDict(state, ownProps),
  isLoading: loading(state, 'password.fetchPolicy'),
});

const mapDispatchToProps = { fetchPasswordPolicy };

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const componentName = 'password-policy-tooltip';
const listClassName = `${componentName}__list`;
const listItemClassName = `${listClassName}-item`;

const PasswordPolicyTooltip = ({
  intl,
  rules,
  errors = [],
  firstValidated,
  fetchPasswordPolicy,
  isLoading,
}) => {
  useEffect(() => {
    fetchPasswordPolicy();
  }, []);

  const getRule = useCallback((rule) => rules[rule], [rules]);
  const isValid = useCallback(
    (rule) => firstValidated && !errors.some(({ type }) => type === rule),
    [errors, firstValidated]
  );

  const renderTest = useCallback(
    ({ key, message }) => {
      const className = classnames(listItemClassName, {
        [`${listItemClassName}--valid`]: isValid(key),
      });
      return (
        <li key={key} className={className}>
          <i className={`${listClassName}-bullet icon-checkmark-circle`} />
          {message}
        </li>
      );
    },
    [isValid]
  );

  const numLastUsedPasswordsOptions = getRule(NUM_LAST_USED_PASSWORDS_RULE);

  if (isLoading) {
    return (
      <div className={componentName}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={componentName}>
      <p className={`${componentName}__item`}>
        {intl.formatMessage({ id: 'passwords.must_contain' })}
      </p>
      <ul className={listClassName}>
        {rulesOrder
          .map(getRule)
          .filter(_identity)
          .map(renderTest)}
      </ul>
      {numLastUsedPasswordsOptions && (
        <p className={`${componentName}__item`}>{numLastUsedPasswordsOptions.message}</p>
      )}
    </div>
  );
};

PasswordPolicyTooltip.propTypes = propTypes;

export default enhance(PasswordPolicyTooltip);
