import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { Field } from 'formik';

import { FormField, Input, Checkbox, Button } from '@showtime/sprocket';

// import { DisplayFormikState } from 'Core/helpers';

class LoginForm extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isUpdating: PropTypes.bool,

    // Props injected by Formik
    values: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      rememberMe: PropTypes.bool,
    }),
    // errors and touched can be empty, and will be populated by Formik if required
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
  };

  renderInput = (fieldKey, shouldRenderIcon) => {
    const {
      intl: { formatMessage },
      values,
      errors,
      touched,
    } = this.props;

    return (
      <FormField
        key={fieldKey}
        label={formatMessage({ id: `login.${fieldKey}` })}
        errorText={errors[fieldKey]}
        hasError={touched[fieldKey] && errors[fieldKey] && errors[fieldKey].length > 0}
      >
        <Field
          name={fieldKey}
          render={(props) => (
            <Input
              className={classnames(`login-form__${fieldKey}`, {
                [`login-form__${fieldKey}--render-icon`]: shouldRenderIcon,
              })}
              placeholder={formatMessage({ id: `login.${fieldKey}` })}
              name={fieldKey}
              type={fieldKey}
              id={fieldKey}
              value={values[fieldKey]}
              {...props}
            />
          )}
        />
      </FormField>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      values,
      isUpdating,
      handleSubmit,
    } = this.props;
    const renderInput = this.renderInput;

    return (
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        {renderInput('email')}

        <ReactIsCapsLockActive>
          {(isActive) => renderInput('password', isActive)}
        </ReactIsCapsLockActive>

        <Field
          name="rememberMe"
          render={(props) => (
            <Checkbox
              label={formatMessage({ id: 'login.remember_me' })}
              name="rememberMe"
              id="rememberMe"
              value={values['rememberMe']}
              {...props}
            />
          )}
        />

        <Button
          disabled={isUpdating}
          className="full-width margin-top-md"
          type="primary"
          size="md"
          isSubmit
        >
          <span>{formatMessage({ id: 'login.sign_in' })}</span>
        </Button>

        {/*<DisplayFormikState {...this.props} />*/}
      </form>
    );
  }
}

export default injectIntl(LoginForm);
