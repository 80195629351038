import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { LOCALES, CURRENCIES } from 'Core/constants';

/*
* Modifies the available languages on the user model to a format which
* suits the User Settings Dropdowns
* */
const getAvailableLanguages = (state, ownProps) => {
  return _filter(state.profile.availableLanguages.map((lang) => {
    const langObj = _find(LOCALES, {code: lang.isoCode});

    if (!langObj) {
      return false;
    }

    const name = langObj ? `${ownProps.intl.formatMessage({id: langObj.key})} (${langObj.native})` : langObj.name;

    return {
      value: lang.id,
      label: name
    };
  }), (lang) => !!(lang));
};


/*
* Modifies the available currencies on the user model to a format which
* suits the User Settings Dropdowns
* */
const getAvailableCurrencies = (state) => {
  return state.profile.availableCurrencies.map((curr) => {
    const currObj = CURRENCIES[curr.isoCode];
    const symbol = currObj ? `(${currObj.symbol_native})` : '';
    const name = `${currObj.name} ${symbol}`;

    return {
      value: curr.id,
      label: name
    };
  });
};


export {
  getAvailableCurrencies,
  getAvailableLanguages
};
