import React, { Fragment, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormField, Input } from '@showtime/sprocket';

import { InputTooltip } from 'Core/components';

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  fieldError: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  tooltipContentRender: PropTypes.func,
  errorMessageRender: PropTypes.func,
  className: PropTypes.string,
};

const PasswordField = (props) => {
  const {
    id,
    label,
    value,
    hasError,
    fieldError,
    tooltipContentRender,
    errorMessageRender,
    className,
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipTriggers = useMemo(() => {
    if (tooltipContentRender) {
      return {
        onFocus: () => setTooltipVisible(true),
        onBlur: () => setTooltipVisible(false),
      };
    }

    return {};
  }, [tooltipContentRender]);
  // eslint-disable-next-line react/prop-types
  const renderField = ({ field, ...fieldProps }) => (
    <Fragment>
      {tooltipContentRender && (
        <InputTooltip isVisible={tooltipVisible}>{tooltipContentRender(props)}</InputTooltip>
      )}
      <Input
        id={id}
        placeholder={label}
        name={id}
        type="password"
        value={value}
        onFocus={tooltipTriggers.onFocus}
        field={{ ...field, onBlur: tooltipTriggers.onBlur }}
        {...fieldProps}
      />
    </Fragment>
  );
  const renderErrorText = useCallback(() => {
    if (errorMessageRender) {
      return errorMessageRender(fieldError);
    }

    return fieldError
      .slice(0, 1)
      .map(({ message }) => message)
      .join(', ');
  }, [fieldError, errorMessageRender]);
  return (
    <FormField key={id} label={label} errorText={renderErrorText()} hasError={hasError}>
      <Field name={id} className={className} render={renderField} />
    </FormField>
  );
};

PasswordField.propTypes = propTypes;

export default React.memo(PasswordField);
