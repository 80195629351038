import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';

import { Alert } from '@showtime/sprocket';
import { isLoadingByPattern } from '@showtime/utility';

import { parseUrlParameters } from 'Core/helpers';
import { FormikEffects } from 'Core/helpers';

import { loginRequest, resetLoginStatus } from './actions';
import LoginForm from './LoginForm';
import { LOGIN_STATUS, LOGIN_SEARCH_ERRORS } from './constants';

const propTypes = {
  intl: intlShape.isRequired,
  location: PropTypes.object,
  isLoggingIn: PropTypes.bool.isRequired,
  loginStatus: PropTypes.string.isRequired,
  resetLoginStatus: PropTypes.func.isRequired,
  loginRequest: PropTypes.func.isRequired,
};

const INITIAL_VALUES = { email: '', password: '', rememberMe: false };

const isLoggingInPattern = /landing\.(loginRequest|getMe)/;

const mapStateToProps = (state) => ({
  loginStatus: state.loginStatus,
  isLoggingIn: isLoadingByPattern(state, isLoggingInPattern),
});

const mapDispatchToProps = { loginRequest, resetLoginStatus };

const enhance = compose(injectIntl, connect(mapStateToProps, mapDispatchToProps));

class Login extends React.Component {
  static propTypes = propTypes;

  state = {
    alert: false,
  };

  LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(this.props.intl.formatMessage({ id: 'login.invalid_email_format' }))
      .required(this.props.intl.formatMessage({ id: 'profile.required_field' })),
    password: Yup.string().required(
      this.props.intl.formatMessage({ id: 'profile.required_field' })
    ),
  });

  formikRef = React.createRef();

  componentDidMount() {
    this.getLoginErrors();
  }

  componentWillUnmount() {
    this.props.resetLoginStatus();
  }

  componentDidUpdate() {
    this.getLoginErrors();
  }

  logIn = (values) => {
    this.props.resetLoginStatus();
    this.setState({ email: values.email });
    this.props.loginRequest({ ...values });
  };

  renderPasswordUpdatedAlert() {
    const { location } = this.props;
    const showAlert = _get(location, 'state.withChangedPassword', false);

    return showAlert ? (
      <Fragment>
        <h2>
          <FormattedMessage id="success" />
        </h2>
        <p className="mb-20">
          <FormattedMessage id="login.updated_password" />
        </p>
      </Fragment>
    ) : null;
  }

  getLoginErrors = () => {
    const { loginStatus } = this.props;
    const searchParams = parseUrlParameters(this.props.location.search);
    const setErrors = _get(this, 'formikRef.current.setErrors', () => console.log('Didnt work'));

    let id = false;
    let status = 'error';

    switch (loginStatus) {
      case LOGIN_STATUS.unauthorized: {
        id = 'login.invalid_credentials';
        break;
      }
      case LOGIN_STATUS.deactivated: {
        id = 'login.account_deactivated';
        break;
      }
      case LOGIN_STATUS.unexpectedError: {
        id = 'login.forgot_pass.unexpected_error';
        break;
      }
      default: {
        // Get error text from the search paramaters by default
        if (
          searchParams.error &&
          Object.values(LOGIN_SEARCH_ERRORS).indexOf(searchParams.error) > -1
        ) {
          id = `login.error.${searchParams.error}`;
          status = 'warning'; //TODO differentiate between warnings/errors
        }
      }
    }

    if (id) {
      this.openAlert(status, id);
      window.setTimeout(() => {
        setErrors({
          email: ' ',
          password: ' ',
        });
      });
    }
  };

  openAlert = (newStatus, newMessage) => {
    const { status = '', message = '' } = this.state.alert;

    if (status !== newStatus || message !== newMessage) {
      this.setState({
        alert: {
          status: newStatus,
          message: newMessage,
        },
      });
    }
  };

  closeAlert = () => {
    this.setState({ alert: false });
  };

  renderAdditionalErrors = () => {
    const values = _get(this, 'formikRef.current.values', INITIAL_VALUES);

    if (values.email === '' && values.password === '') {
      this.openAlert('error', 'login.email_password_error');
    }
  };

  render() {
    const isLoggingIn = this.props.isLoggingIn;
    const alert = this.state.alert;
    const renderAdditionalErrors = this.renderAdditionalErrors;

    return (
      <Fragment>
        {!isLoggingIn && alert && (
          <Alert type="embedded" status={alert.status}>
            <FormattedMessage id={alert.message} />
          </Alert>
        )}

        {this.renderPasswordUpdatedAlert()}

        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={this.LoginSchema}
          validateOnChange={false}
          onSubmit={this.logIn}
          ref={this.formikRef}
          render={(formProps) => (
            <Fragment>
              <FormikEffects formik={formProps} onSubmissionError={renderAdditionalErrors} />
              <LoginForm isUpdating={isLoggingIn} {...formProps} />
            </Fragment>
          )}
        />

        <div className="text-center p-15 size-14">
          <Link to="/user/forgot-password" className="pointer show-underline">
            <FormattedMessage id="login.forgot_pass" />
          </Link>
        </div>
      </Fragment>
    );
  }
}

export default enhance(Login);
