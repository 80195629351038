import {SAVE_ORIGINAL_URL} from './actionTypes';

const initialState = {
  originalUrl: null
};

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORIGINAL_URL:
      return  {
        ...state,
        originalUrl: action.url
      };
    default:
      return state;
  }
};

export default navigation;
