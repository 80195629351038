import React from 'react';
import classnames from 'classnames';

const BASE_CLASS = 'input-tooltip';

// TODO: Reuse ReactTooltip?
// Sprocket's Input is so not ready for that, but this is reinventing the wheel
const InputTooltip = ({ children, isVisible }) => (
  <div className={classnames(BASE_CLASS, { [`${BASE_CLASS}--is-visible`]: isVisible })}>
    {children}
  </div>
);

export default InputTooltip;
