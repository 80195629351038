import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import classnames from 'classnames';
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _some from 'lodash/some';
import { errorByPattern, isLoadingByPattern, clearError } from '@showtime/utility';
import { Alert } from '@showtime/sprocket';
import { FormikEffects } from 'Core/helpers';

import { showBanner } from 'NotificationBanner';
import { updatePassword, resetPassword } from './actions';
import { getPasswordRequiredSchema, getPasswordPolicySchema } from './selectors';
import { RULE_MESSAGE_IDS, NUM_LAST_USED_PASSWORDS_RULE } from './constants';
import PasswordForm from './PasswordForm';

const propTypes = {
  intl: intlShape.isRequired,
  isUpdating: PropTypes.bool,
  updatePassword: PropTypes.func,
  resetPassword: PropTypes.func,
  showBanner: PropTypes.func,
  clearErrors: PropTypes.func,
  match: PropTypes.object,
  updatePasswordError: PropTypes.object,
  token: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  passwordRequiredSchema: PropTypes.object.isRequired,
  passwordPolicySchema: PropTypes.object.isRequired,
};

const defaultProps = {
  updatePasswordError: {},
  token: false,
};

const INITIAL_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const passwordPattern = /(settings\.update|landing\.reset)Password/;
const initalFormStatus = {
  firstValidated: false,
};

const mapStateToProps = (state, ownProps) => ({
  passwordRequiredSchema: getPasswordRequiredSchema(state, ownProps),
  passwordPolicySchema: getPasswordPolicySchema(state, ownProps),
  isUpdating: isLoadingByPattern(state, passwordPattern),
  updatePasswordError: errorByPattern(state, passwordPattern),
});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (oldPassword, newPassword) => dispatch(updatePassword(oldPassword, newPassword)),
  resetPassword: (password, key) => dispatch(resetPassword(password, key)),
  showBanner: (status, message) => dispatch(showBanner(status, message)),
  clearErrors: () => {
    dispatch(clearError('landing.resetPassword'));
    dispatch(clearError('settings.updatePassword'));
  },
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

class Password extends Component {
  static defaultProps = defaultProps;
  static propTypes = propTypes;

  state = {
    hasToken: false,
    hasExpired: false,
    alert: false,
    incorrectPassword: false,
  };

  formikRef = React.createRef();

  componentDidMount() {
    const {
      match: { path },
    } = this.props;
    const token = this.getToken();

    this.setState({
      hasToken: !!token,
      hasExpired: path.includes('/password-expired'),
    });
  }

  componentDidUpdate(prevProps) {
    // TODO: move to thunk ?? Check setState
    const { updatePasswordError, clearErrors } = this.props;

    if (
      !_isEqual(updatePasswordError, prevProps.updatePasswordError) &&
      !_isUndefined(updatePasswordError.error)
    ) {
      switch (updatePasswordError.error.status) {
        case 400:
          this.handleInvalidServerError(updatePasswordError);
          break;
        case 404:
          this.openAlert('error', 'passwords.invalid_token');
          clearErrors();
          break;
        case 500:
          if (_get(updatePasswordError, 'error.body.errorCode', '') === 'invalid_password') {
            this.setState({
              incorrectPassword: true,
            });
            this.openAlert('error', 'passwords.incorrect');
          } else {
            this.openAlert('error', 'login.unexpected_error');
          }
          clearErrors();
          break;
        default:
          break;
      }
    }
  }

  handleInvalidServerError = (error) => {
    const failedValidations = _filter(
      _get(error, 'error.body.body.validationChecklist', []),
      ({ result }) => !result
    );
    const hasLastNumUsedPass = _some(
      failedValidations,
      ({ key }) => NUM_LAST_USED_PASSWORDS_RULE === key
    );

    if (failedValidations.length === 1 && hasLastNumUsedPass) {
      const { value } = failedValidations[0];
      this.openAlert(
        'error',
        <FormattedMessage
          id="passwords.validations.does_not_match_last_amount"
          values={{ amount: value }}
        />
      );
      this.setFieldErrorStates();
    } else if (failedValidations.length) {
      this.openAlert(
        'error',
        <Fragment>
          <div>
            <FormattedMessage
              id="passwords.error.failed_validations"
              values={{ amount: failedValidations.length }}
            />
          </div>
          <ul className="pl-10 mt-5">
            {failedValidations.map(({ key, value }) => {
              return (
                <li key={key}>
                  <FormattedMessage
                    id={`passwords.validations.${RULE_MESSAGE_IDS[key]}`}
                    values={{ amount: value }}
                  />
                </li>
              );
            })}
          </ul>
        </Fragment>
      );
      this.setFieldErrorStates();
    }
  };

  getToken = () => _get(this.props, 'match.params.token', false);

  setFieldErrorStates = () => {
    const setErrors = _get(this, 'formikRef.current.setErrors', () => {});

    // This needs to be in a timeout to allow formik async processes to complete
    // before setting the field errors.
    window.setTimeout(() => {
      const blankError = [
        {
          type: 'invalid',
          message: '',
        },
      ];
      setErrors({
        newPassword: blankError,
        confirmPassword: blankError,
      });
    });
  };

  isReset = () => {
    return this.state.hasToken || this.state.hasExpired;
  };

  openAlert = (status, message) => {
    this.setState({
      alert: { status, message },
    });
  };

  clearLocalErrors = () => {
    //Clearing form errors
    const setErrors = _get(this, 'formikRef.current.setErrors', () => {});
    setErrors({});

    this.setState({
      incorrectPassword: false,
      alert: false,
    });
  };

  updatePassword = (values) => {
    const { resetPassword, updatePassword } = this.props;
    const token = this.getToken();

    if (this.isReset()) {
      resetPassword(values.newPassword, token);
    } else {
      updatePassword(values.currentPassword, values.newPassword);
    }
  };

  isPasswordCorrect = () => {
    return !this.state.incorrectPassword;
  };

  buildValidationSchema() {
    const { passwordPolicySchema, passwordRequiredSchema } = this.props;

    let shape = {
      newPassword: passwordRequiredSchema.concat(passwordPolicySchema),
      confirmPassword: passwordRequiredSchema.oneOf([Yup.ref('newPassword')], ''),
    };

    if (!this.isReset()) {
      shape.currentPassword = passwordRequiredSchema.test(
        'incorrect-password',
        '',
        this.isPasswordCorrect
      );
    }

    return Yup.object(shape);
  }

  transformYupErrors(err) {
    // Formik extracts only the first error message for each field.
    // Doesn't work for rich validation messages.
    // This form will expose errors as array of Yup objects for each field
    return _groupBy(err.inner, 'path');
  }

  setFirstValidate = () => {
    // firstValidated is a property kinda like `touched` for PasswordPolicy,
    // but leave touched to deal with submission errors.
    this.formikRef.current.setStatus({ firstValidated: true });
  };

  validate = (formValue) => {
    const schema = this.buildValidationSchema();

    return schema
      .validate(formValue, { abortEarly: false })
      .then(this.setFirstValidate)
      .catch((err) => {
        this.setFirstValidate();
        throw this.transformYupErrors(err);
      });
  };

  renderAdditionalErrors = ({ values }) => {
    const openAlert = this.openAlert;

    // This needs to be in a timeout to allow formik async processes to complete
    // before setting the field errors.
    window.setTimeout(() => {
      if (values.confirmPassword !== values.newPassword) {
        openAlert('error', 'passwords.must_match');
        this.setFieldErrorStates();
      }
    });
  };

  renderTitle = () => {
    const hasExpired = this.state.hasExpired;
    const isReset = this.isReset;

    return (
      <FormattedMessage
        id={
          hasExpired
            ? 'passwords.please_change_password'
            : `${isReset ? 'reset_passwords' : 'passwords'}.change_password`
        }
      />
    );
  };

  render() {
    const { isUpdating } = this.props;
    const { hasExpired, alert } = this.state;
    const { isReset, renderTitle, renderAdditionalErrors } = this;

    return (
      <div
        className={classnames({
          'settings-form': !isReset(),
          'landing-form': isReset(),
        })}
      >
        {isReset() && (
          <div className="mb-50">
            <Link to="/user/login">
              <FormattedMessage id="back_to_signin" />
            </Link>
          </div>
        )}

        {alert && (
          <Alert status={alert.status}>
            <FormattedMessage id={alert.message} />
          </Alert>
        )}

        <h1 className="settings-form__title">{renderTitle()}</h1>

        {hasExpired ? (
          <p className="settings-form__subtitle">
            <FormattedMessage id="passwords.expired" />
          </p>
        ) : null}

        <Formik
          onSubmit={this.updatePassword}
          initialValues={INITIAL_VALUES}
          validateOnBlur={false}
          validate={this.validate}
          initialStatus={initalFormStatus}
          ref={this.formikRef}
          render={(formProps) => (
            <Fragment>
              <FormikEffects formik={formProps} onSubmissionError={renderAdditionalErrors} />
              <PasswordForm
                askForCurrentPassword={!this.isReset()}
                isUpdating={isUpdating}
                hasUpdated={this.clearLocalErrors}
                actionMessage={isReset() ? 'passwords.reset_password' : 'passwords.change_password'}
                {...formProps}
              />
            </Fragment>
          )}
        />
      </div>
    );
  }
}

export default enhance(Password);
