/* global process */
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';

const logger = createLogger({ collapsed: true });
const prod = process.env.NODE_ENV !== 'development';
export const history = createBrowserHistory();

export default function configureStore() {
  const composeEnhancers =
    !prod && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = !prod
    ? composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, logger))
    : composeEnhancers(applyMiddleware(routerMiddleware(history), thunk));

  const store = createStore(reducers(history), enhancer);

  return store;
}
