import * as actions from './actionTypes';

const initialState = {
  isVisible: false,
  status: 'info',
  message: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.SHOW_BANNER:
      return  {
        isVisible: true,
        status: action.status,
        message: action.message
      };

    case actions.HIDE_BANNER:
      return {
        ...state,
        isVisible: false
      };

    default:
      return state;
  }
};

export default reducer;
