import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import { hasRule, setScreenRules } from '@showtime/permissions';

import { LOCALSTORAGE_USER_KEY, LOCALSTORAGE_LOCALE_KEY, DEFAULT_LOCALE } from './constants';

export { DisplayFormikState, FormikEffects } from './formikHeplers';

export const getAppIndustry = () => {
  return document.documentElement.dataset.industry;
}

export const getUserModel = () => {
  const userModel = localStorage.getItem(LOCALSTORAGE_USER_KEY);
  return _isNull(userModel) ? null : JSON.parse(userModel);
};

export const getLocaleFromStorage = () => {
  const userLocale = localStorage.getItem(LOCALSTORAGE_LOCALE_KEY);
  return _isNull(userLocale) ? DEFAULT_LOCALE : userLocale;
};

export const setLocaleToStorage = (locale) => {
  localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, locale);
};

export const isAuthenticated = () => !_isNull(getUserModel());

export const getProductPath = () => {
  const userModel = getUserModel();
  setScreenRules(userModel.screenRules);
  let path = '/user';

  if (hasRule('showtime.insights')) {
    path = '/insights';
  } else if (hasRule('showtime.engage')) {
    path = '/engage';
  } else if (hasRule('showtime.circuitAdmin')) {
    path = '/circuit-admin';
  } else if (hasRule('showtime.benchmark')) {
    path = '/benchmark';
  } else if (hasRule('showtime.beta')) {
    path = '/beta';
  } else if (hasRule('showtime.gower')) {
    path = '/gower';
  } else if (hasRule('showtime.community')) {
    path = '/community';
  }

  return path;
};

export const parseUrlParameters = (input) => {
  if (input === '' || _isUndefined(input)) {
    return {};
  }

  const output = {};

  input
    .replace(/^\?/, '')
    .split('&')
    .forEach((node) => {
      const nodeValues = node.split('=');
      output[nodeValues[0]] = decodeURIComponent(nodeValues[1]);
    });
  return output;
};

/*
 * $1 first character
 * $2 more characters preceding '@'
 * $3 rest of email
 * Expected result: example@domain.com -> e****@domain.com
 */
const hideEmailReplace = '$1****$3';
const hideEmailPattern = /(^.)(.*)(@.*$)/;
export const hideEmail = (email) => email.replace(hideEmailPattern, hideEmailReplace);
