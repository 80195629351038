import React, { Fragment, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { locationShape, historyShape } from 'react-router-props';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import {
  Navbar,
  SidebarNav,
  ProductSidebar,
  ProductSidebarNav,
} from '@showtime/sprocket';

import NotificationBanner from 'NotificationBanner';
import { getUserModel } from 'Core/helpers';
import { connect } from 'react-redux';
import { getMe } from 'Auth/actions';

const menuItems = (formatMessage) => [
  {
    sectionTitle: formatMessage({ id: 'user_settings' }),
    sectionItems: [
      {
        title: formatMessage({ id: 'profile_settings' }),
        url: '/user/settings',
      },
      {
        title: formatMessage({ id: 'login.password' }),
        url: '/user/password',
      },
    ],
  },
];

const Frame = (props) => {
  useEffect(() => {
    props.getMe();
  }, []);

  return (
    <Fragment>
      <Navbar headerTitle={'SETTINGS'} className="navigation-bar--user-settings" />

      <SidebarNav
        onLogout={() => props.history.push('/user/logout')}
        onAlertMessage={() => console.log('onAlertMessage()')}
        hasIntercom={false}
        userModel={getUserModel()}
      />
      <main className="app-frame">
        <ProductSidebar className="product-sidebar--user-settings">
          <ProductSidebarNav
            sections={menuItems(props.intl.formatMessage)}
            Link={Link}
            activePath={props.location.pathname}
          />
        </ProductSidebar>

        <div className="app-frame__content">
          <NotificationBanner />
          <div className="app-frame__content__page">
            {props.children}
          </div>
        </div>

      </main>
    </Fragment>
  );
};

Frame.propTypes = {
  intl: intlShape,
  location: locationShape,
  history: historyShape,
  getMe: PropTypes.func,
  children: PropTypes.node
};

const enhance = compose(withRouter, injectIntl, connect(null, { getMe }));

export default enhance(Frame);

