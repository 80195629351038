import { createReducer } from 'redux-starter-kit';

import { getLocaleFromStorage } from 'Core/helpers';

import verificationReducer from './Verification/reducer';

import {
  FETCH_PROFILE_SUCCESS,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
  RESET_USER_PROFILE,
  SET_LOGIN_STATUS,
  SET_GENERAL_PARAMETERS,
} from './actionTypes';

const initialProfileState = {
  id: null,
  name: { firstName: '', lastName: '' },
  email: '',
  language: getLocaleFromStorage(),
  currency: 'EUR',
  languageId: 1,
  currencyId: 1,
  availableLanguages: [],
  availableCurrencies: [],
  organization: { id: null, name: '' },
  circuits: [
    {
      id: null,
      name: '',
      domain: '',
      startHour: 6,
      startDay: 5,
      weekend: '5-6-7',
      attributes: '{}',
      currency: 'EUR',
    },
  ],
  policies: [],
  screenRules: [],
  configs: [],
};

// TODO tidy up these reducers
const profileReducer = createReducer(initialProfileState, {
  [FETCH_PROFILE_SUCCESS]: (_, action) => action.profile,
  [RESET_USER_PROFILE]: () => ({ ...initialProfileState, language: getLocaleFromStorage() }),
});

const forgotPasswordStatusReducer = createReducer(null, {
  [FORGOT_PASSWORD_EMAIL_SUCCESS]: (_, action) => action.status,
});

const loginStatusReducer = createReducer('', {
  [SET_LOGIN_STATUS]: (_, action) => action.status,
});

const initialParameters = {
  reVerificationDays: 0,
  orgName: '',
  minutesOfLifeVerifyLink: 0,
  expirePolicy: { lifetimeHours: 0, notifyDays: 0 },
};

const generalParametersReducer = createReducer(initialParameters, {
  [SET_GENERAL_PARAMETERS]: (_, action) => action.parameters,
});

export default {
  profile: profileReducer,
  forgotPasswordStatus: forgotPasswordStatusReducer,
  loginStatus: loginStatusReducer,
  generalParameters: generalParametersReducer,
  ...verificationReducer,
};
